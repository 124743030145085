import './App.css';
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom';
import { useEffect } from 'react'; // ✅ Добавляем useEffect
import { UserProvider } from "../src/Context/UserContext"; // Подключаем контекст
import Header from './Components/Header/Header';
import Main from './Components/Main/Main';
import Footer from './Components/Footer/Footer';
import Map from './Components/Main/Main-Components/Map/Map';
import Fighter from './Pages/fighters/Fighter';
import Rate from './Pages/Rate/Rate';
import Account from './Pages/account/Account';
import Login from './Pages/account/LogIn/Login';
import Register from './Pages/account/Register/Register';
import { AuthProvider } from './Context/AuthContext';
import Profile from './Pages/account/profile/Profile';
import Settings from './Pages/account/Settings/Settings';
import Publikacija from './Pages/account/Publikacija/Publikacija';
import UserPostProfile from './Pages/account/Publikacija/UserPost/UserPostProfile';
import Activities from './Pages/account/activityes/Activities';
import AdminPanel from './Pages/account/adminPanel/AdminPanel';
import CreateActivity from './Pages/account/activityes/CreateActivity';
import ForgotPassword from './Pages/account/LogIn/ForgotPassword';
import AdminMaratons from './Pages/account/Coach/Maratons/AdminMaratons.jsx';
import Maratons from './Pages/account/activityes/Maratons/Maratons.jsx';
import PrivateRoute from './routes.js';
import Gym from './Pages/account/Coach/Gym/Gym.jsx';
import AthleteList from './Pages/account/Coach/Athletete/AthleteList.jsx';
import GymQRPage from './Pages/account/activityes/GymQRCode/GymQRPage.jsx';
import ToggleGym from './Pages/account/activityes/GymQRCode/ToggleGym.jsx';
// 🔥 Импортируем Firebase
import { requestNotificationPermission, onForegroundMessage } from './firebase.js';

function App() {
  useEffect(() => {
      requestNotificationPermission(); // Запрос разрешений на пуш-уведомления

      // 🔔 Правильный способ обработки входящих уведомлений
      onForegroundMessage((payload) => {
          console.log('🔔 Получено уведомление:', payload);
          alert(`${payload.notification.title}: ${payload.notification.body}`);
      });
  }, []);
  return (
    <UserProvider>
      <HashRouter>
      <AuthProvider>
        <Header />
        <Routes>
          {/* Перенаправление на /main */}
          <Route path='/' element={<Navigate to="/main" />} />
          
          {/* Main как контейнер для страниц */}
          <Route path='/main' element={<Main />} />
          {/* <Route path='/fighter' element={<Fighter />} /> */}
          <Route path='/map' element={<Map />} />
          <Route path='/rate' element={<Rate />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register/>} />
          <Route path='/forgotPassword' element={<ForgotPassword/>}/>
          <Route path="/account" element={<PrivateRoute><Account /></PrivateRoute>}>
            <Route path="profile/:id" element={<PrivateRoute><Profile /></PrivateRoute>}>
              <Route path='userPostProfile' element={<UserPostProfile/>}/>
            </Route>
            <Route path='settings' element={<PrivateRoute><Settings/></PrivateRoute>}/>
            <Route path='publikacija' element={<Publikacija/>}/>
            <Route path="activities" element={<PrivateRoute><Activities /></PrivateRoute>} />
            <Route path='maratons' element={<PrivateRoute><Maratons/></PrivateRoute>}/>
            <Route path="adminPanel" element={<PrivateRoute><AdminPanel /></PrivateRoute>} />
            <Route path='createActivity' element={<PrivateRoute><CreateActivity/></PrivateRoute>}/>
            <Route path='gym' element={<PrivateRoute><Gym/></PrivateRoute>}/>
            <Route path='athleteList' element={<PrivateRoute><AthleteList/></PrivateRoute>}/>
            <Route path='adminMaratons' element={<PrivateRoute><AdminMaratons/></PrivateRoute>}/>
            <Route path='fighter' element={<PrivateRoute><Fighter /></PrivateRoute>} />
            <Route path='qrCodeGym' element={<PrivateRoute><GymQRPage/></PrivateRoute>}/>
          </Route>
          <Route path="/toggle-gym" element={<ToggleGym />} />
        </Routes>
        <Footer />
        </AuthProvider>
      </HashRouter>
    </UserProvider>
  );
}

export default App;
