import React, { useEffect, useState } from 'react';
import SportistsRateCard from './SportistRateCard.jsx';
import '../fighters/fighter.scss';
import API from '../../utils/api.js';
export default function Rate() {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await API.get('/api/users'); // Получаем данные пользователей
                setUsers(response.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, []);

    // Сортируем пользователей по рейтингу
    const sortedUsers = [...users].sort((a, b) => b.rating - a.rating);

    return (
        <div className='rate-category-container'>
            <div>
                <h2>Рейтинг спортсменов</h2>
            </div>
            <div className='fighter-card-block'>
                {sortedUsers.map((user, index) => (
                    <SportistsRateCard
                        key={index}
                        img={`http://localhost:3001${user.profile_picture || '/static/default-avatar.png'}`}
                        fighter={user.username}
                        activityRate={user.rating}
                        discipline={`Funs: ${user.currency}`} // Валюта
                    />
                ))}
            </div>
        </div>
    );
}
