import React, { createContext, useState, useEffect } from "react";
import API from "../utils/api";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [permissions, setPermissions] = useState([]); // ✅ Добавили permissions
    const [forceUpdate, setForceUpdate] = useState(false); // Флаг для перерисовки

    useEffect(() => {
        const storedUserId = localStorage.getItem("userId");
        const storedRole = localStorage.getItem("userRole");
        const storedUsername = localStorage.getItem("username") || "Неизвестный"; // ✅ Фикс
        const storedToken = localStorage.getItem("accessToken"); // ✅ Проверяем, есть ли сохраненный токен


        if (storedUserId && storedToken) {
            setUser({ id: storedUserId, role: storedRole, username: storedUsername, token: storedToken });
        }
    }, []);

    useEffect(() => {
        if (!user?.id) return;

        const fetchPermissions = async () => {
            try {
                const response = await API.get('/users/permissions', {
                    headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
                });

                console.log("🔍 [DEBUG] Полученные разрешения с API:", response.data.permissions);
                setPermissions(response.data.permissions);
            } catch (error) {
                console.error('Ошибка загрузки разрешений:', error);
            }
        };

        fetchPermissions();
    }, [user?.id]); // ✅ permissions обновляются при изменении пользователя

    const loginUser = (id, role, token) => {
        localStorage.setItem('accessToken', token);
        localStorage.setItem('userId', id);
        localStorage.setItem('role', role);
        setUser({ id, role }); // ✅ Обновляем состояние, чтобы не перезагружать страницу
    };

    const logoutUser = () => {
        localStorage.clear();
        setUser(null);
        setPermissions([]); // ✅ Очищаем permissions при выходе

    };

    const switchUser = (newUserId, newRole, newToken, newUsername) => {
        console.log("Переключение на пользователя:", newUserId, newRole, newUsername);

        // ✅ Фикс: проверяем `username`, если `undefined`, берем из `localStorage`
        const finalUsername = newUsername || localStorage.getItem("username") || "Неизвестный";

        localStorage.setItem("userId", newUserId);
        localStorage.setItem("userRole", newRole);
        localStorage.setItem("username", finalUsername);
        if (newToken) {
            localStorage.setItem("accessToken", newToken);
        } else {
            newToken = localStorage.getItem("accessToken"); // ✅ Берем старый токен, если нового нет
        }

        setUser({ id: newUserId, role: newRole, username: finalUsername, token: newToken });


        // 🔥 Форсируем обновление всех компонентов
        setForceUpdate((prev) => !prev);
    };

    return (
        <UserContext.Provider value={{ user, setUser, switchUser, permissions, forceUpdate, loginUser, logoutUser }}>
            {children}
        </UserContext.Provider>
    );
};
