import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// ✅ Firebase-конфигурация
const firebaseConfig = {
  apiKey: "AIzaSyAiB09kl8TxxQW3ZvFzZyKKp4v4DrwmJzE",
  authDomain: "funcatchers-742e7.firebaseapp.com",
  projectId: "funcatchers-742e7",
  storageBucket: "funcatchers-742e7.appspot.com",
  messagingSenderId: "1056486705470",
  appId: "1:1056486705470:web:9580fd5de5c7b8ad99046f",
  measurementId: "G-E2NJQJW37C"
};

// ✅ Инициализация Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// ✅ Функция запроса разрешения на уведомления
export const requestNotificationPermission = async () => {
    try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
            const token = await getToken(messaging);
            console.log('✅ FCM Token:', token);

            // Отправляем токен на сервер
            await fetch('https://fc-server.zapto.org/api/users/save-fcm-token', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token })
            });
        } else {
            console.warn('🚨 Уведомления отключены пользователем');
        }
    } catch (error) {
        console.error('❌ Ошибка получения FCM токена:', error);
    }
};

// ✅ Правильный обработчик входящих уведомлений
export const onForegroundMessage = (callback) => {
    onMessage(messaging, callback);
};

// ✅ Теперь `messaging` экспортируется правильно
export { messaging };
