import React, {useState, useEffect} from 'react';
import '../activities.scss';
import API from '../../../../utils/api';
import { useNavigate } from 'react-router-dom';
export default function ParticipantsModal({ activityId, participants, onClose, updateParticipation, id  }) {

      const [profileData, setProfileData] = useState(null);

    const navigate = useNavigate();
    
    const userRole = localStorage.getItem('role'); // Роль пользователя

    const handleAttendanceChange = async (userId, isChecked) => {
        try {
            updateParticipation(userId, activityId, isChecked);
        } catch (error) {
            console.error('Ошибка при обновлении статуса участия:', error);
        }
    };

    const handleProfileClick = (userId) => {
        navigate(`/account/profile/${userId}`);
    };
    console.log("Participants:", participants);

    useEffect(() => {
        const fetchProfiles = async () => {
            try {
                const responses = await Promise.all(participants.map(p => API.get(`/users/${p.id}`)));
                const profiles = responses.map(res => res.data);
                setProfileData(profiles);
            } catch (error) {
                console.error("Ошибка загрузки профилей:", error);
            }
        };
    
        if (participants.length > 0) {
            fetchProfiles();
        }
    }, [participants]);
    

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h3>Dalībnieki</h3>
                    <ul>
                    {participants.map((participant) => (
                        <li key={participant.id} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <img
                            onClick={() => handleProfileClick(participant.id)}
                            className="participant-avatar"
                            src={
                            participant.profile_picture
                                ? `https://fc-server.zapto.org/${participant.profile_picture}`
                                : '/static/default-avatar.png'
                            }
                            alt="Profile"
                        />
                        <span>{participant.first_name}</span>
            <span className='participant-role-class'>{participant.role} ({participant.class})</span>
                        {/* Отображение чекбокса для администраторов */}
                            {['superadmin', 'admin', 'moderator', 'coach'].includes(userRole) && (
                                <input
                                    type="checkbox"
                                    checked={participant.attended || false} // Отображение значения из API
                                    onChange={(e) => handleAttendanceChange(participant.id, e.target.checked)} // Обновление по клику
                                />
                                                       
                            )}

                        </li>
                    ))}
                    </ul>
                <button onClick={onClose}>Aizvērt</button>
            </div>
        </div>
    );
}

