import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { UserContext } from './Context/UserContext'; // Импортируем контекст
import Login from './Pages/account/LogIn/Login'; // Импортируем окно входа

const PrivateRoute = ({ children }) => {
    const { user } = useContext(UserContext);
    const location = useLocation();

    if (!user) {
        return <Login closeModal={() => {}} redirectPath={location.pathname} />;
    }

    return children;
};

export default PrivateRoute;
