import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './adminpanel.scss'
import API from '../../../utils/api';

const AdminPanel = () => {
    const [statuses, setStatuses] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedRole, setSelectedRole] = useState('');
    const [selectedClass, setSelectedClass] = useState('');
    const [rating, setRating] = useState(0);
    const [currency, setCurrency] = useState(0);
    const [roles, setRoles] = useState([]);
    const [classes, setClasses] = useState([]);

    const fetchStatuses = async () => {
        try {
            const response = await API.get('/admin/statuses', {
                headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
            });
            setStatuses(response.data);
        } catch (error) {
            console.error('Error fetching statuses:', error);
        }
    };

    const fetchUsers = async () => {
        try {
            const response = await axios.get('https://fc-server.zapto.org/api/users/', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                },
            });
            setUsers(response.data);
        } catch (error) {
            console.error('Ошибка загрузки пользователей:', error);
        }
    };

    const fetchRoles = async () => {
        try {
            const response = await axios.get('https://fc-server.zapto.org/api/users/roles', {
                headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
            });
            setRoles(response.data);
        } catch (error) {
            console.error('Ошибка загрузки ролей:', error);
        }
    };

    const fetchClasses = async () => {
        try {
            const response = await axios.get('https://fc-server.zapto.org/api/users/classes', {
                headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
            });
    
            const classesData = response.data?.classes || [];
            setClasses(classesData);
        } catch (error) {
            console.error('Ошибка загрузки классов:', error);
            setClasses([]);
        }
    };

    useEffect(() => {
        fetchRoles();
        fetchClasses();
        fetchStatuses();
        fetchUsers();
    }, []);

    const handleRoleChange = async () => {
        if (!selectedUser || !selectedRole) {
            return alert('Выберите пользователя и роль');
        }

        try {
            const response = await axios.post(
                'https://fc-server.zapto.org/api/users/update-role',
                { userId: selectedUser, newRole: selectedRole },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
                }
            );
            alert(response.data.message);
            fetchUsers();
        } catch (error) {
            console.error('Ошибка обновления роли пользователя:', error);
        }
    };

    const handleClassChange = async () => {
        if (!selectedUser || !selectedClass) {
            return alert('Выберите пользователя и класс');
        }

        try {
            const response = await axios.post(
                'https://fc-server.zapto.org/api/users/update-class',
                { userId: selectedUser, newClass: selectedClass },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
                }
            );
            alert(response.data.message);
            fetchUsers();
        } catch (error) {
            console.error('Ошибка обновления класса пользователя:', error);
        }
    };

    const handleSubmit = async () => {
        if (!selectedUser) {
            return alert('Выберите пользователя.');
        }

        try {
            const response = await axios.post('https://fc-server.zapto.org/api/users/update-stats', {
                userId: selectedUser,
                ratingPoints: parseInt(rating, 10) || 0,
                currency: parseInt(currency, 10) || 0,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                },
            });
            alert(response.data.message);
        } catch (error) {
            console.error('Ошибка обновления данных пользователя:', error);
        }
    };

    return (
        <div className='conteiner-admin-panel'>
            <div>
                <h1>Administrācijas panelis</h1>
                {/* <h2>Statuses</h2>
                <ul>
                    {statuses.map(status => <li key={status}>{status}</li>)}
                </ul>
                <input
                    type="text"
                    placeholder="Add new status"
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') addStatus(e.target.value);
                    }}
                /> */}
            </div>
            <div>
                <h2>Points</h2>
                <div>
                    <label htmlFor="userSelect">Choose User</label>
                    <select
                        id="userSelect"
                        onChange={(e) => setSelectedUser(e.target.value)}
                    >
                        <option value="">--Select User--</option>
                        {users.map(user => (
                            <option key={user.id} value={user.id}>
                                {user.username} ({user.email})
                            </option>
                        ))}
                    </select>
                </div>

                {/* Выбор роли */}
                <div>
                    <label>Выберите новую роль:</label>
                    <select onChange={(e) => setSelectedRole(e.target.value)}>
                        <option value="">--Выберите роль--</option>
                        {roles.map(role => (
                            <option key={role} value={role}>{role}</option>
                        ))}
                    </select>
                </div>
                <button onClick={handleRoleChange}>Сменить роль</button>

                {/* Выбор класса */}
                <div>
                    <label>Выберите новый класс:</label>
                    <select onChange={(e) => setSelectedClass(e.target.value)}>
                        <option value="">--Выберите класс--</option>
                        {Array.isArray(classes) ? (
                            classes.map(classItem => (
                                <option key={classItem} value={classItem}>{classItem}</option>
                            ))
                        ) : (
                            <option disabled>Ошибка загрузки классов</option>
                        )}
                    </select>
                </div>
                <button onClick={handleClassChange}>Сменить класс</button>

                {/* Обновление рейтинга и валюты */}
                <div>
                    <label htmlFor="ratingInput">Rating Points</label>
                    <input
                        type="number"
                        id="ratingInput"
                        value={rating}
                        onChange={(e) => setRating(e.target.value)}
                    />
                </div>

                <div>
                    <label htmlFor="currencyInput">Currency</label>
                    <input
                        type="number"
                        id="currencyInput"
                        value={currency}
                        onChange={(e) => setCurrency(e.target.value)}
                    />
                </div>

                <button onClick={handleSubmit}>Submit</button>
            </div>
        </div>
    );
};

export default AdminPanel;
