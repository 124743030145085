import React, { useState, useEffect } from 'react';
import axios from 'axios';
import API from '../../../utils/api';

const CreateActivity = () => {
    // const [gymState, setGymState] = useState(null);
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        start_time: '',
        end_time: '',
        max_participants: '',
        rating_points: '',
        currency_reward: '',
        status: '',
        coach: '',
        is_marathon: false // ✅ Теперь оно всегда будет либо true, либо false

    });
    const [templates, setTemplates] = useState([]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value // Обрабатываем чекбокс
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const preparedData = {
                ...formData,
                start_time: new Date(formData.start_time).toISOString(),
                end_time: new Date(formData.end_time).toISOString(),
                // start_time: formData.start_time, // Оставляем как есть
                // end_time: formData.end_time,     // Оставляем как есть
                max_participants: Number(formData.max_participants),
                rating_points: Number(formData.rating_points),
                currency_reward: Number(formData.currency_reward),
                status: formData.status.toLowerCase(),
                is_marathon: formData.is_marathon // ✅ Проверяем, передается ли значение
            };
            console.log('Prepared Data:', preparedData); // Добавляем вывод в консоль
    
            const response = await API.post('/activities/create', preparedData, {
                headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
            });
            console.log(response);
            alert('Activity created successfully');
            setFormData({
                title: '',
                description: '',
                start_time: '',
                end_time: '',
                max_participants: '',
                rating_points: '',
                currency_reward: '',
                status: '',
                coach: '', // Добавляем поле для выбора тренера
                is_marathon: false // Добавляем флаг марафона
            });
        } catch (error) {
            console.error('Error creating activity:', error);
            alert(error.response?.data?.message || 'Failed to create activity');
        }
    };

    const trainers = [
        'Aleksandrs Aleksejenko',
        'Nastja Kožukovska',
        'Iļja Kožukovskis',
        'Viktorija Dangele',
        'Iļja Plonskis',
        'Matveii Nikitenko',
        'Milana Loginova'
    ];

    const handleSaveTemplate = async () => {
        try {
            await API.post('/activities/save-template', formData, {
                headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
            });
            alert('Шаблон сохранён!');
        } catch (error) {
            console.error('Ошибка сохранения шаблона:', error);
            alert('Ошибка при сохранении шаблона');
        }
    };
    
    useEffect(() => {
        const fetchTemplates = async () => {
            try {
                const response = await API.get('/activities/templates', {
                    headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
                });
                setTemplates(response.data);
            } catch (error) {
                console.error('Ошибка загрузки шаблонов:', error);
            }
        };
    
        fetchTemplates();
    }, []);
    
    const applyTemplate = (template) => {
        setFormData({
            title: template.title,
            description: template.description,
            start_time: template.start_time,
            end_time: template.end_time,
            max_participants: template.max_participants,
            rating_points: template.rating_points,
            currency_reward: template.currency_reward,
            status: template.status,
            coach: template.coach,
            is_marathon: template.is_marathon,
        });
    };

    return (
        <div className="create-activity">
            <h2>Create Activity</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="title"
                    placeholder="Title"
                    value={formData.title}
                    onChange={handleChange}
                    required
                />
                <textarea
                    name="description"
                    placeholder="Description"
                    value={formData.description}
                    onChange={handleChange}
                    required
                ></textarea>
                <input
                    type="datetime-local"
                    name="start_time"
                    placeholder="Start Time"
                    value={formData.start_time}
                    onChange={handleChange}
                    required
                />
                <input
                    type="datetime-local"
                    name="end_time"
                    placeholder="End Time"
                    value={formData.end_time}
                    onChange={handleChange}
                    required
                />
                <input
                    type="number"
                    name="max_participants"
                    placeholder="Max Participants"
                    value={formData.max_participants}
                    onChange={handleChange}
                    required
                />
                <input
                    type="number"
                    name="rating_points"
                    placeholder="Rating Points"
                    value={formData.rating_points}
                    onChange={handleChange}
                    required
                />
                <input
                    type="number"
                    name="currency_reward"
                    placeholder="Currency Reward"
                    value={formData.currency_reward}
                    onChange={handleChange}
                    required
                />
                <select
                    name="status"
                    value={formData.status}
                    onChange={handleChange}
                    required
                >
                    <option value="">Выберите категорию</option>
                    <option value="trainings">Тренировки</option>
                    <option value="competitions">Соревнования</option>
                    <option value="events">Мероприятия</option>
                    <option value="Others">Другое</option>
                </select>

                <select name="coach" value={formData.coach} onChange={handleChange} required>
                <option value="">Выберите тренера</option>
                {trainers.map(trainer => (
                    <option key={trainer} value={trainer}>{trainer}</option>
                ))}
            </select>
            {/* ✅ Чекбокс для выбора марафона */}
            <label>
                <input type="checkbox" name="is_marathon" checked={formData.is_marathon} onChange={handleChange} />
                Это марафон?
            </label>
                <button type="submit">Create</button>
                <button type="button" onClick={handleSaveTemplate}>Сохранить как шаблон</button>

            </form>
            <div>
                <h3>Выберите шаблон:</h3>
                {templates.map(template => (
                    <button key={template.id} onClick={() => applyTemplate(template)}>
                        {template.title}
                    </button>
                ))}
            </div>

        </div>
    );
};

export default CreateActivity;
