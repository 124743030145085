import React from 'react'
import { useNavigate } from 'react-router-dom';
import './price.scss'

export default function Price() {
    const navigate = useNavigate();

    const sendToActivities = () => {
        navigate('/account/activities');
    }
   
    const iljaPhoneNmber = '+37129427994';
    const firstComplekt = encodeURIComponent('Gribu nopirkt pakalpojuma komplektu 1')
    const whatsAppFirstComplekt = `https://wa.me/${iljaPhoneNmber}?text=${firstComplekt}`;
    const kikbokss = encodeURIComponent('Gribu pierakstīties uz kikboksa nodarbību')
    const whatsAppKik = `https://wa.me/${iljaPhoneNmber}?text=${kikbokss}`;
    const ChildThreeKikbokss = encodeURIComponent('Gribu pierakstīties uz bērnu nodarbību 3+')
    const whatsAppChildThreeKikbokss = `https://wa.me/${iljaPhoneNmber}?text=${ChildThreeKikbokss}`;
    const ChildSixKikbokss = encodeURIComponent('Gribu pierakstīties uz bērnu nodarbību 6+')
    const whatsAppChildSixKikbokss = `https://wa.me/${iljaPhoneNmber}?text=${ChildSixKikbokss}`;
    const Bokss = encodeURIComponent('Gribu pierakstīties uz Boksa nodarbību')
    const whatsAppBokss = `https://wa.me/${iljaPhoneNmber}?text=${Bokss}`;
    const Taekwondo = encodeURIComponent('Gribu pierakstīties uz Taekwondo nodarbību')
    const whatsAppTaekwondo = `https://wa.me/${iljaPhoneNmber}?text=${Taekwondo}`;
    const oneWalk = encodeURIComponent('Gribu pierakstīties uz 1 Apmeklejumu')
    const whatsAppOneWalk = `https://wa.me/${iljaPhoneNmber}?text=${oneWalk}`;
    const personal = encodeURIComponent('Gribu pierakstīties uz individuālu nodarbību')
    const whatsAppPersonal = `https://wa.me/${iljaPhoneNmber}?text=${personal}`;
    const nastjaPhoneNmber = '+37126897551';
    const Fitness = encodeURIComponent('Gribu pierakstīties uz fitnesu')
    const whatsAppFitness = `https://wa.me/${nastjaPhoneNmber}?text=${Fitness}`;
    const Kikbokss = encodeURIComponent('Gribu pierakstīties uz Kikboksa nodarbību')
    const whatsAppKikbokss = `https://wa.me/${nastjaPhoneNmber}?text=${Kikbokss}`;
    const AllIn = encodeURIComponent('Vēlos iegādāties pilno abonementu')
    const whatsAppAllIn = `https://wa.me/${nastjaPhoneNmber}?text=${AllIn}`;
    
  return (
    <div id='price' className='price-conteiner'>
        <h2>Pakalpojumi</h2>
        <div className='price-list'>
            <div className='price-block'>
                <h2>Pakalpojuma komplekts 1</h2>
                <h4>25 Eur</h4>
                <h6>Tikai zāle</h6>
                <p>Neierobežota pieeja trenažieru zālei un boksa zālei patstāvīgam darbam.</p>
                <button onClick={sendToActivities}><b>Pierakstīties</b></button>
                <button onClick={() =>{window.location.href = whatsAppFirstComplekt}}><b>WhatsApp</b></button>            
            </div>
            <div className='price-block'>
                <h2>Pakalpojuma komplekts 2</h2>
                <h4>45 EUR</h4>
                <h6>Boks</h6>
                <p>45 Eur Boks + trenažieru zāle</p>
                <button onClick={sendToActivities}><b>Pierakstīties</b></button>
                <button onClick={() =>{window.location.href = whatsAppBokss}}><b>WhatsApp</b></button>            
            </div>
            <div className='price-block'>
                <h2>Pakalpojuma komplekts 3</h2>
                <h4>20 - 35 EUR</h4>
                <h6>Taekwondo</h6>
                <p>25 EUR tikai Taekwondo nodarbības</p>
                <p>35 Eur Taekwondo + trenažieru zāle</p>
                <button onClick={sendToActivities}><b>Pierakstīties</b></button>
                <button onClick={() =>{window.location.href = whatsAppTaekwondo}}><b>WhatsApp</b></button>

            </div>
            <div className='price-block'>
                <h2>Pakalpojuma komplekts 4</h2>
                <h4>35 - 45 Eur</h4>
                <h6>Fitness</h6>
                <p>Fitnesa treniņš</p>
                <p>
                    35 Eur Ja bērns iet uz nodarbībam 
                <br />
                    45 Eur Ja neviens no ģimenes locekļiem neapmeklē nodarbības
                </p>
                <button onClick={sendToActivities}><b>Pierakstīties</b></button>
                <button onClick={() =>{window.location.href = whatsAppFitness}}><b>WhatsApp</b></button>            
            </div>
            <div className='price-block'>
                <h2>Pakalpojuma komplekts 5</h2>
                <h4>55 Eur</h4>
                <h6>Kikbokss</h6>
                <p>Kikboksa nodarbību apmeklējums + trenažieru zāle</p>
                <button onClick={sendToActivities}><b>Pierakstīties</b></button>
                <button onClick={() =>{window.location.href = whatsAppKikbokss}}><b>WhatsApp</b></button>            
            </div>
        </div>
        <div className='price-list-second'>
            <div className='price-block'>
                <h2>Pakalpojuma komplekts 6</h2>
                <h4>75 Eur</h4>
                <h6>All In</h6>
                <p>Pilnais abonements, ar kuru var apmeklēt jebkuras nodarbības</p>
                <button onClick={sendToActivities}><b>Pierakstīties</b></button>
                <button onClick={() =>{window.location.href = whatsAppAllIn}}><b>WhatsApp</b></button>            
            </div>    
            <div className='price-block'>
                <h2>Bērnu komplekts 1</h2>
                <h4>35 Eur</h4>
                <p>Nodarbības bērniem no 3 līdz 5 gadiem</p>
                <button onClick={sendToActivities}><b>Pierakstīties</b></button>
                <button onClick={() =>{window.location.href = whatsAppChildThreeKikbokss}}><b>WhatsApp</b></button>            
            </div>
            <div className='price-block'>
                <h2>Bērnu komplekts 2</h2>
                <h4>45 Eur</h4>
                <p>Nodarbības bērniem no 6 līdz 10 gadiem</p>
                <button onClick={sendToActivities}><b>Pierakstīties</b></button>
                <button onClick={() =>{window.location.href = whatsAppChildSixKikbokss}}><b>WhatsApp</b></button>            
            </div>
            <div className='price-block'>
                <h2>1 Apmeklejuma</h2>
                <h4>5 - 10 EUR</h4>
                <p>5 EUR Vienreizējs boksa zāles vai trenažieru zāles apmeklējums</p>
                <p>10 EUR Vienreizējs grupu treniņu apmeklējums</p>
                <button onClick={sendToActivities}><b>Pierakstīties</b></button>
                <button onClick={() =>{window.location.href = whatsAppOneWalk}}><b>WhatsApp</b></button>            
            </div>
            <div className='price-block'>
                <h2>Individuāla nodarbībā</h2>
                <h4>20 - 40 EUR</h4>
                <p>Individuālās apmācības cena ir atkarīga no trenera</p>
                <button onClick={sendToActivities}><b>Pierakstīties</b></button>
                <button onClick={() =>{window.location.href = whatsAppPersonal}}><b>WhatsApp</b></button>            
            </div>
        </div>
    </div>
  )
}
