import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../../Context/UserContext';
import ParticipantsModal from './participantsModal/ParticipantsModal';
import API from '../../../utils/api';
import imgGym from './img/GYM.jpg';
import imgGym1 from './img/GYM1.jpg';
import GymQRCode from '../activityes/GymQRCode/GymQRCode';

const socket = new WebSocket('ws://localhost:3001');

const Activities = () => {
    const [activities, setActivities] = useState([]);
    const [statistics, setStatistics] = useState(null);
    const [participants, setParticipants] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedActivityId, setSelectedActivityId] = useState(null);
    const [gymParticipants, setGymParticipants] = useState(0);
    const [isGymOpen, setIsGymOpen] = useState(false);
    const [gymActivity, setGymActivity] = useState(null);
    const [gymState, setGymState] = useState(null);
    const [isJoined, setIsJoined] = useState(false);
    const [activeTab, setActiveTab] = useState('upcoming'); // По умолчанию "Предстоящие"

    const { user } = useContext(UserContext);
    const userId = user?.id;


    const getGymState = async () => {
        try {
            const response = await API.get('/activities/gym-state');
            setIsGymOpen(response.data.isOpen);
        } catch (error) {
            console.error('Ошибка при получении состояния зала:', error);
        }
    };
    const fetchGymState = async () => {
        try {
            const response = await API.get('/activities/gym');
            setGymState(response.data);

            // Проверяем, присоединился ли текущий пользователь
            const userId = JSON.parse(atob(localStorage.getItem('accessToken').split('.')[1])).id;
            const participantsResponse = await API.get(`/activities/participants/${response.data.id}`);
            const isUserJoined = participantsResponse.data.some((participant) => participant.id === userId);
            setIsJoined(isUserJoined);
        } catch (error) {
            console.error('Ошибка при загрузке состояния зала:', error);
        }
    };
    
    const fetchGymActivity = async () => {
        try {
            const response = await API.get('/activities/gym', {
                headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
            });
            setGymActivity(response.data);
        } catch (error) {
            console.error('Ошибка при загрузке данных о зале:', error);
        }
    };

    useEffect(() => {
        socket.onopen = () => {
            // console.log('WebSocket соединение установлено');
        };

        socket.onmessage = async (event) => {
            const data = JSON.parse(event.data);
            if (data.type === 'activityFinalized') {
            //   console.log('Активность завершена:', data.message);
              await fetchActivities(); // Обновляем активности
              const updatedStats = await fetchStatistics(); // Обновляем статистику
              setStatistics(updatedStats);
            }
          };
          

        return () => socket.close(); // Закрываем соединение при размонтировании
    }, []);

    const fetchStatistics = async () => {
        try {
          const response = await API.get('/activities/statistics', {
            headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
          });
        //   console.log('Статистика обновлена:', response.data);
          // Вы можете использовать setStatistics, если нужно сохранить статистику в состоянии
        } catch (error) {
          console.error('Ошибка при загрузке статистики:', error);
        }
      };
      
    const fetchMonthlyStats = async () => {
        try {
            const response = await API.get('/activities/statistics/monthly', {
                headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
            });
            // console.log('Monthly stats fetched:', response.data);
            // Обновление состояния или вызов функции, которая будет использовать данные
        } catch (error) {
            console.error('Ошибка загрузки статистики по месяцам:', error);
        }
    };
    
    // Получение списка активностей
    // Загрузка активностей
    const fetchActivities = async () => {
        if (!user?.id) return;
        try {
            const response = await API.get('/activities', {
                headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
            });
            const activitiesData = response.data;
    
            // Проверка и автоматическое завершение активностей
            activitiesData.forEach(activity => {
                if (new Date(activity.end_time) < new Date() && !activity.isFinalized) {
                    finalizeActivity(activity.id); // Автоматическое завершение
                }
            });

            // Исключаем Gym из общего списка
             const filteredActivities = activitiesData.filter(activity => activity.status !== 'gym');

    
            setActivities(activitiesData);
            setActivities(filteredActivities);
        } catch (error) {
            console.error('Ошибка при загрузке активностей:', error);
        }
    };
    

    // Получение участников конкретной активности
    const fetchParticipants = async (activityId) => {
        try {
            setSelectedActivityId(activityId); // Устанавливаем ID текущей активности
            const response = await API.get(`/activities/participants/${activityId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
            });
            setParticipants(response.data); // Обновляем состояние участников
            setShowModal(true);
        } catch (error) {
            console.error('Ошибка при получении участников:', error);
            alert(error.response?.data?.message || 'Не удалось загрузить список участников');
        }
    };
    useEffect(() => {
        if (selectedActivityId) {
            fetchParticipants(selectedActivityId);
        }
    }, [selectedActivityId]);
    
    
    

    // Подтверждение или отклонение участия
    const updateParticipation = async (userId, activityId, isChecked) => {
        console.log('Отправляем данные:', { userId, activityId, attended: isChecked }); // ✅ Проверяем данные перед отправкой
        try {
            // Отправляем запрос на сервер
            const response = await API.post('/activities/confirm',{
                activityId,
                userId,
                attended: isChecked, // Состояние чекбокса
            });
              setParticipants((prevParticipants) =>
                prevParticipants.map((participant) =>
                    participant.id === userId
                        ? { ...participant, attended: isChecked }
                        : participant
                )
            );
            // Обновляем статистику
            fetchMonthlyStats();
        } catch (error) {
            console.error('Ошибка при обновлении участия:', error);
            alert('Ошибка при обновлении участия');
        }
    };
    
      
    

    // Присоединение к активности
    const joinActivity = async (activityId) => {
        try {
            await API.post('/activities/participate', { activity_id: activityId }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
            });
            alert('Успешно присоединились к активности');
            fetchActivities();
        } catch (error) {
            console.error('Ошибка при присоединении к активности:', error);
            const message = error.response?.data?.message || 'Не удалось присоединиться к активности';
            alert(`Ошибка: ${message}`);
        }
    };

    // Выход из активности
    const leaveActivity = async (activityId) => {
        try {
            await API.post('/activities/leave', { activity_id: activityId }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
            });
            alert('Вы успешно покинули активность');
            fetchActivities(); // Обновляем список активностей
        } catch (error) {
            console.error('Ошибка при выходе из активности:', error);
            alert(error.response?.data?.message || 'Не удалось выйти из активности');
        }
    };  

    // Логика подсветки активности
    const getStatusColor = (activity) => {
        const now = new Date();
        const startTime = new Date(activity.start_time);
        const endTime = new Date(activity.end_time);

        if (now > endTime) return 'red'; // Активность завершена
        if (startTime - now <= 2 * 60 * 60 * 1000) return 'yellow'; // Менее 2 часов до начала
        if (activity.userJoined) return 'green'; // Пользователь записан

        return 'white'; // Обычный статус
    };

    const formatDateHeader = (date) => {
        const days = ['Svētdiena', 'Pirmdiena', 'Otrdiena', 'Trešdiena', 'Ceturtdiena', 'Piektdiena', 'Sestdiena'];
        const dayOfWeek = days[date.getDay()];
        return `${dayOfWeek}, ${date.toLocaleDateString()}`;
    };

    const fetchGymParticipants = async () => {
        try {
            const response = await API.get('/activities/participants/gym');
            setGymParticipants(response.data.length);
        } catch (error) {
            console.error('Ошибка при загрузке участников зала:', error);
        }
    };

    useEffect(() => {
        fetchActivities();
        fetchGymParticipants();
        fetchGymActivity();
        fetchGymState();
    }, []);

    // Автоматическое завершение активности
    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();
            activities.forEach(activity => {
                const endTime = new Date(activity.end_time);
    
                // Проверяем, завершена ли активность
                if (!activity.isFinalized && now >= endTime) {
                    finalizeActivity(activity.id);
                }
            });
        }, 60000); // Проверка каждые 60 секунд
    
        return () => clearInterval(interval);
    }, [activities]);
    
    const finalizeActivity = async (activityId) => {
        if (!activityId) {
            console.error('activityId не указан.');
            return;
        }
        try {
            await API.post(`/activities/finalize/${activityId}`, {}, {
                headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
            });
            // console.log(`Активность ${activityId} успешно завершена`);
    
            // Обновляем статистику
            fetchStatistics();
            fetchMonthlyStats();
            fetchActivities(); // Обновляем список активностей
        } catch (error) {
            // console.error(`Ошибка при завершении активности ${activityId}:`, error.response?.data?.message || error.message);
        }
    };
      useEffect(() => {
        const interval = setInterval(() => {
          activities.forEach(activity => {
            if (!activity.isFinalized && new Date(activity.end_time) < new Date()) {
                // console.log(`Активность ${activity.id} уже завершена.`);
                finalizeActivity(activity.id).then(() => {
                    // Обновляем статистику после завершения активности
                    fetchStatistics();
                    fetchMonthlyStats(); // Если есть отдельная функция для месячной статистики
                    // console.log(`Активность ${activity.id} успешно завершена.`);
                  });
            }
          });
        }, 60000); // Каждые 60 секунд
      
        return () => clearInterval(interval);
      }, [activities]);

      const joinGym = async () => {
        try {
            await API.post('/activities/gym-visit');
            setIsJoined(true);
            setGymState((prev) => ({
                ...prev,
                participants_count: prev.participants_count + 1,
            }));
            alert('Вы успешно записаны в зал.');
        } catch (error) {
            console.error('Ошибка при записи в зал:', error);
            alert(error.response?.data?.message || 'Не удалось записаться в зал.');
        }
    };
    const unjoinGym = async () => {
        try {
            await API.post('/activities/leave', { activity_id: gymState.id }); 
            setIsJoined(false);
            setGymState((prev) => ({
                ...prev,
                participants_count: Math.max(prev.participants_count - 1, 0),
            }));
            alert('Вы успешно покинули зал.');
        } catch (error) {
            console.error('Ошибка при выходе из зала:', error);
            alert('Произошла ошибка при выходе из зала.');
        }
    };
    
    const groupedActivities = activities.reduce((acc, activity) => {
        const date = new Date(activity.start_time).toDateString();
        if (!acc[date]) acc[date] = [];
        acc[date].push(activity);
        return acc;
    }, {});

    // Фильтрация активностей по вкладке
    const filteredActivities = () => {
        const now = new Date();
        let filtered = [];

        if (activeTab === 'upcoming') {
            // Предстоящие активности
            filtered = activities.filter(activity => new Date(activity.end_time) > now);
        } else if (activeTab === 'myActivities') {
            // Мои активности
            filtered = activities.filter(activity => activity.userJoined);
        } else if (activeTab === 'completed') {
            // Завершенные активности
            filtered = activities.filter(activity => new Date(activity.end_time) <= now);
        }

        // Сортировка по времени начала (ближайшие сверху)
        return filtered.sort((a, b) => new Date(a.start_time) - new Date(b.start_time));
    };

    // if (!activity) {
    //     return <div className="downloads">Загрузка...</div>;
    //   }
    

    return (
        <div className='activities-conteiner'>
            <h1>Aktivitātes</h1>
            {/* Меню переключения разделов */}
            {gymState && (
                <div
                    className='gym-block'
                    style={{
                        background: `
                            linear-gradient(
                                rgba(${isJoined ? "0,128,0,0.6" : gymState.is_gym_open ? "255,255,255,0.6" : "255,0,0,0.6"}),
                                rgba(${isJoined ? "0,128,0,0.6" : gymState.is_gym_open ? "255,255,255,0.6" : "255,0,0,0.6"})
                            ),
                            url(${imgGym1})
                        `,
                        // backgroundSize: "contain",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        // backgroundRepeat: "no-repeat",
                        backgroundColor: isJoined
                            ? "linear-gradient(rgba(0, 128, 0, 0.6), rgba(0, 128, 0, 0.6)), url(${imgGym})"
                            : gymState.is_gym_open
                            ? `url(${imgGym})`
                            : "linear-gradient(rgba(255, 0, 0, 0.6), rgba(255, 0, 0, 0.6)), url(${imgGym})",
                        padding: '20px',
                        borderRadius: '8px',
                    }}
                >
                    {/* <img src={imgGym} alt="" /> */}
                    <div>
                    <h2 onClick={() => fetchParticipants(gymState.id)}>Gym</h2>

                    <h3 style={{ color: gymState.is_gym_open ? 'black' : 'red' }}>
                            {gymState.is_gym_open ? 'Open' : 'Close'}
                        </h3>
                    </div>
                    {/* ✅ Добавляем QR-код под названием зала */}
                    {/* <GymQRCode /> */}
                    <p>Zāle: {gymState.participants_count}</p>
                    {isJoined ? (
                        <button className="activity-button leave" onClick={unjoinGym}>Atcelt dalību</button>
                    ) : (
                        <button className="activity-button join" onClick={joinGym} disabled={!gymState.is_gym_open}>
                            Pievienoties
                        </button>
                    )}
                </div>
            )}
            {showModal && (
            <ParticipantsModal
                    participants={participants}
                    onClose={() => setShowModal(false)}
                />
            )}
            <div className="tabs">
                <button className={activeTab === 'upcoming' ? 'active' : ''} onClick={() => setActiveTab('upcoming')}>Gaidāmās</button>
                <button className={activeTab === 'myActivities' ? 'active' : ''} onClick={() => setActiveTab('myActivities')}>Manas aktivitātes</button>
                <button className={activeTab === 'completed' ? 'active' : ''} onClick={() => setActiveTab('completed')}>Pabeigtās</button>
            </div>
            {/* Список активностей с заголовками дат */}
            {/* Список активностей */}
            {/* Список активностей с заголовками дат */}
{/* Список активностей с разделением по дням */}
{Object.entries(
    filteredActivities().reduce((acc, activity) => {
        const date = new Date(activity.start_time).toDateString();
        if (!acc[date]) acc[date] = [];
        acc[date].push(activity);
        return acc;
    }, {})
).map(([date, activitiesForDate]) => (
    <div key={date}>
        <h2 className='Activities-day'>{formatDateHeader(new Date(date))}</h2>
        <ul>
            {activitiesForDate.map(activity => (
                <div key={activity.id} style={{ backgroundColor: getStatusColor(activity), margin: '10px', padding: '10px', borderRadius: '5px' }}>
                    <h2 onClick={() => fetchParticipants(activity.id)}>{activity.title}</h2>
                    <p>Treneris: {activity.coach}</p>
                    <div>
                        <p>Reitings: {activity.rating_points}</p>
                        <p>Funs: {activity.currency_reward}</p>
                    </div>
                    <p>{activity.description}</p>
                    <p>Pievienojas {activity.participants_count}/{activity.max_participants || '∞'}</p>
                    <p>Sākums: {new Date(activity.start_time).toLocaleString()}</p>
                    <p>Beigas: {new Date(activity.end_time).toLocaleString()}</p>
                    {activity.userJoined ? (
                        <button className="activity-button leave" onClick={() => leaveActivity(activity.id)}>Atcelt dalību</button>
                    ) : (
                        <button 
                            className="activity-button join"  
                            onClick={() => joinActivity(activity.id)} 
                            disabled={(activity.max_participants > 0 && activity.participants_count >= activity.max_participants) || new Date(activity.start_time) - new Date() <= 3600000}
                            >
                            Pievienoties
                        </button>
                    )}
                </div>
            ))}
        </ul>
    </div>
))}



            {showModal && (
                <ParticipantsModal
                    activityId={selectedActivityId} // Передаём корректный ID
                    participants={participants}
                    onClose={() => setShowModal(false)}
                    updateParticipation={updateParticipation}
                />
            )}

        </div>
    );
};

export default Activities;