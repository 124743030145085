import React from 'react'
import './grafik.scss'

export default function Grafik() {
  return (
    <>
        <h2 id='calendar'>
            Grafik
        </h2>
        <div className='grafik-table-block'>
            <iframe src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTDTTrxVZAIkJEsEhqn_A0zYnvdu-gVTTQkY8Y3f6_nNat52lp8e493Nm5saWFc-73CUNaklHPJ23wt/pubhtml?gid=0&amp;single=true&amp;widget=true&amp;headers=false"
                className='grafik-table'
            >   
            </iframe>
            {/* <iframe src="https://calendar.google.com/calendar/embed?src=513a45a085f1a0a979fcae808c983ba967533fcddf62b6be33a588eb3ae6b907%40group.calendar.google.com&ctz=Europe%2FRiga" 
             width="100%" height="600" frameBorder="0" scrolling="no">
            </iframe> */}
        </div>
    </>
)
}
